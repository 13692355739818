<template>
    <div class="userService">
        <!--导航 H5 使用-->
        <rx-nav-bar v-if="type==1"  :title="this.detail.textTitle"></rx-nav-bar>

        <!--导航 原生 使用-->
        <rx-nav-bar v-if="type!=1" :title="this.detail.textTitle" androidOrIOSFlag="true"></rx-nav-bar>
<!--        服务详情-->
        <div id="details"></div>
    </div>
</template>

<script>
    import { NavBar} from 'vant';
    import {responseUtil} from "../../libs/rongxunUtil";
    import {bannerLinkUser} from "../../getData/getData";
    import RxNavBar from "../../components/rongxun/rx-navBar/navBar";

    export default {
        components: {
            RxNavBar,
            [NavBar .name]:NavBar,
        },
        data() {
            return {
                type:'',        //原生orH5
                textType:'',   //富文本类型
                richId: '',    //富文本id
                detail : {},
            }
        },
        mounted() {
            //
            this.type = this.$route.query.type
            this.richId = this.$route.query.richId || '' // 富文本id 轮播图用  可以为空
            this.textType = this.$route.query.textType
            this.initData()
        },
        beforeRouteEnter(to, from, next) {
            //清除本地localStorage缓存
            if(from.name == null){
                localStorage.removeItem('currentLabel');
                localStorage.removeItem('type');
                localStorage.removeItem('inputValue');
                localStorage.removeItem('currentLabelPublic');
            }
            next()
        },
        methods :{
            leftReturn(){
                this.$router.go(-1)
            },
            initData(){
                let that = this
                let initData = {}
                if(that.richId != ''){
                    initData.richId = that.richId
                }
                initData.textType = that.textType
                bannerLinkUser(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.detail=response.data.data.textContent
                        if(response.data.data.textContent == undefined || response.data.data.textContent == ""){
                            that.detail= "暂无内容"
                        }
                        document.getElementById("details").innerHTML=that.detail
                    })
                })
            }

        }
    }
</script>

<style lang="less" scoped >
    .nav{
        position: fixed;
        width: 100%;
    }
    .placeholder{
        height: 30px;
    }
</style>